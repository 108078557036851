import React, { useEffect } from 'react'
import styled from 'styled-components'
import { layout, breakpoints } from '../../theme'
import Helmet from 'react-helmet'
import LargeBanner, {
  StyledImg,
  StyledLargeBanner,
} from '../../components/LargeBanner'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import { useRedirect } from '../../hooks/useRedirect'
import { graphql } from 'gatsby'

const DemandThankYouPage = ({ location: { state }, ...props }) => {
  const hasQuoted = state && state.quote
  useRedirect(!hasQuoted, '/')
  //  BING ADS CONVERSION TRACKING
  useEffect(() => {
    if (typeof window !== 'undefined' && window.uetq) {
      const frontUrl = process.env.GATSBY_VENUS_FRONT_URL
      if (frontUrl) {
        const fullUrl = `${frontUrl}/evenements/`
        window.uetq.push('event', fullUrl, {})
      }
    }
  }, [])

  return (
    <StyledDemandThankYouPage>
      <Helmet meta={[{ name: 'robots', content: 'noindex' }]} />
      <Navbar />
      <div className="page-content">
        <LargeBanner {...props.data.banner} pictureClipped>
          <h1>Nous avons bien reçu votre demande. Merci !</h1>
          <p>Vous allez recevoir votre devis d’ici 3 heures.</p>
        </LargeBanner>
      </div>
      <Footer />
    </StyledDemandThankYouPage>
  )
}

const StyledDemandThankYouPage = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .page-content {
    margin-top: ${layout.navbarHeight.normal}px;
    flex-grow: 1;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
    }
  }

  ${StyledLargeBanner} {
    height: 400px;

    .text-wrapper {
      width: 60%;
    }

    ${StyledImg} {
      width: 40%;
      margin-left: 60%;

      img {
        object-fit: cover !important;
      }
    }
  }

  ${StyledLargeBanner} .wrapper,
  .content {
    margin: 0 auto;
    max-width: ${layout.maxWidth}px;
    width: ${layout.width};
  }

  h2 {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  p,
  span {
    font-size: 1.2rem;
    margin-bottom: 25px;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    ${StyledLargeBanner} > .picture > ${StyledImg} {
      transform: none;
      margin-left: 40%;
      width: 60%;
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    ${StyledLargeBanner} {
      height: 200px;
      padding-top: 55px;

      .text-wrapper {
        width: 100%;
      }
    }
  }

  &.no-content + footer {
    display: none;
  }
`

export const query = graphql`
  {
    banner: contentfulCallToAction(fixedId: { eq: "cta-2" }) {
      ...CallToActionFragment
    }
  }
`

export default DemandThankYouPage
export { StyledDemandThankYouPage }
